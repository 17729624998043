<template>
  <v-main>
    <v-container fluid page>
      <v-row align="center">
        <!-- Title -->
        <v-col cols="12">
          <h2 class="display-1 font-weight-light pt-5 pl-5">
            {{ $t('history.title') }}
          </h2>
        </v-col>
        <!-- Title -->

        <v-col cols="12" v-if="error">
          <v-alert border="left" colored-border type="error" elevation="2">
            <strong v-if="code">{{ code }}</strong>
            {{ message }}
          </v-alert>
        </v-col>

        <!-- Orders list -->
        <v-col cols="12">
          <v-card>
            <v-row class="px-2">
              <!-- Filtro por numero de mesa -->
              <v-col cols="12" md="6" class="pb-0">
                <v-select
                  v-model="filter.type"
                  :items="typeList"
                  label="Tipo de pedido"
                  multiple
                  chips
                  item-value="id"
                  item-text="value"
                  prepend-icon="mdi-chart-bubble"
                  deletable-chips
                ></v-select>
              </v-col>

              <!-- Filtro por estado del pedido -->
              <v-col cols="12" md="6" class="py-0 align-self-end">
                <v-select
                  v-model="filter.status"
                  :items="statusList"
                  label="Estado del pedido"
                  multiple
                  chips
                  item-value="id"
                  item-text="value"
                  prepend-icon="mdi-chart-bubble"
                  deletable-chips
                ></v-select>
              </v-col>

              <!-- Filtro por numero de mesa -->
              <v-col cols="12" sm="6" md="2" class="py-0">
                <v-select
                  v-model="filter.table"
                  :items="tableList"
                  label="Número de mesa"
                  item-value="uuid"
                  item-text="number"
                  prepend-icon="mdi-table-chair"
                ></v-select>
              </v-col>

              <!-- Filtro por pedido pagado -->
              <v-col cols="12" sm="6" md="2" class="py-0">
                <v-select
                  v-model="filter.paid"
                  :items="paidList"
                  label="Pedido pagado"
                  item-value="id"
                  item-text="value"
                  prepend-icon="mdi-currency-eur"
                ></v-select>
              </v-col>

              <!-- Date From -->
              <v-col cols="12" sm="6" md="4" class="py-0">
                <v-menu
                  v-model="activeFrom"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="filter.dateFrom"
                      label="Fecha desde"
                      prepend-icon="mdi-calendar"
                      readonly
                      clearable
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="es-es"
                    v-model="filter.dateFrom"
                    @input="activeFrom = false"
                    show-current
                    no-title
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <!-- Date to -->
              <v-col cols="12" sm="6" md="4" class="py-0">
                <v-menu
                  v-model="activeTo"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="filter.dateTo"
                      label="Fecha hasta"
                      prepend-icon="mdi-calendar"
                      readonly
                      clearable
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="es-es"
                    v-model="filter.dateTo"
                    @input="activeTo = false"
                    show-current
                    no-title
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <!-- Buttons -->
              <v-col cols="12" class="text-right">
                <v-btn @click="reset" class="ma-1">Limpiar filtros</v-btn>
                <v-btn color="primary" @click="search(1)" class="ma-1"
                  >Buscar</v-btn
                >
              </v-col>
            </v-row>

            <v-row align="center" no-gutters class="px-2">
              <v-col class="shrink align-self-start">
                <v-card-text class="order-history">
                  <v-checkbox
                    :value="checked"
                    :indeterminate="indeterminate"
                    @change="toggle"
                    color="primary"
                    hide-details
                  ></v-checkbox>
                </v-card-text>
              </v-col>

              <v-col>
                <v-card flat tile class="order-history">
                  <v-row align="center" no-gutters>
                    <v-col cols="2" md="1">
                      <v-card-text class="subtitle-1 text-truncate px-1"
                        >Mesa</v-card-text
                      >
                    </v-col>

                    <v-col class="grow d-none d-md-flex">
                      <v-card-text class="subtitle-1 text-truncate px-1"
                        >Fecha y hora</v-card-text
                      >
                    </v-col>

                    <v-col cols="2" md="1">
                      <v-card-text class="subtitle-1 text-truncate px-1"
                        >Pagado</v-card-text
                      >
                    </v-col>

                    <v-col cols="4" md="3">
                      <v-card-text class="subtitle-1 text-truncate px-1"
                        >Estado</v-card-text
                      >
                    </v-col>

                    <v-col cols="4" md="3">
                      <v-card-text
                        class="text-right subtitle-1 text-truncate px-1"
                        >Detalles</v-card-text
                      >
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>

              <v-col cols="12" class="px-2">
                <v-alert
                  :value="orders.length == 0"
                  border="left"
                  colored-border
                  type="info"
                  elevation="2"
                  class="mt-4"
                  >{{ $t('orders.noOrders') }}</v-alert
                >
              </v-col>
            </v-row>

            <eximyr-order-history
              v-for="(order, index) in orders"
              :key="order.id"
              :order="order"
              :selectedOrders="selectedOrders"
              :last="index + 1 == orders.length"
              @toggle="toggleOrder"
              @open="open"
            ></eximyr-order-history>

            <v-card-text v-if="orders.length > 0" class="text-right">
              <v-row>
                <!-- Buttons -->
                <v-col cols="12">
                  <!-- FIXME: Implemta el boton imprimir para sacar el ticket de los pedidos seleccionados 
                  <v-btn color="primary" class="ma-1">Imprimir ticket</v-btn>
                  -->
                  <v-btn
                    class="ma-1"
                    color="primary"
                    @click="updatePaid(null)"
                    :disabled="!enableUpdatePaidButton"
                  >
                    Pagado(s)
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Orders list -->

        <!-- Start pagination -->
        <v-col cols="12">
          <div class="text-right my-4">
            <v-pagination
              v-model="page"
              :length="last_page"
              :total-visible="7"
              @input="search"
            ></v-pagination>
          </div>
        </v-col>
        <!-- End pagination -->
      </v-row>
    </v-container>

    <!-- Details dialog -->
    <eximyr-order-dialog
      v-show="dialog"
      :order="order"
      :products="products"
      :daily-menus="dailyMenus"
      :hide-history-button="true"
      :hide-ticket-view-button="false"
      @close="close"
      @save="save($event)"
      @showTicketDialog="showTicketDialog"
    ></eximyr-order-dialog>
    <!-- Details dialog -->

    <!-- Ticket View Dialog -->
    <EasyServeTicketView
      :dialog="ticketDialog"
      :order="order"
      :products="products"
      :daily-menus="dailyMenus"
      @close="close"
      @showDialog="showDialog"
      @updatePaid="updatePaid($event)"
    ></EasyServeTicketView>
    <!-- Ticket View Dialog -->

    <!-- Loading screen -->
    <eximyr-loading
      :loading="
        loading || loadingTables || historyLoading || orderDetailsLoading
      "
    ></eximyr-loading>
    <!-- Loading screen -->
  </v-main>
</template>

<script>
import errors from '../mixins/errors';
import goTo from 'vuetify/lib/services/goto';
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';

import EximyrLoading from '../components/EximyrLoading';

import EximyrOrderDialog from './components/EximyrOrderDialog';
import EximyrOrderHistory from './components/EximyrOrderHistory';
import EasyServeTicketView from './components/EasyServeTicketView';

export default {
  mixins: [errors],

  components: {
    EximyrLoading,

    EximyrOrderDialog,
    EximyrOrderHistory,
    EasyServeTicketView,
  },

  data: () => ({
    page: null,
    loading: false,
    loadingTables: false,

    dialog: false,
    ticketDialog: false,
    order: null,

    code: '',
    message: '',
    error: null,

    statusList: [
      {
        id: 'new',
        value: 'Nuevo',
      },
      {
        id: 'acepted',
        value: 'Aceptado / preparando',
      },
      {
        id: 'rejected',
        value: 'Rechazado',
      },
      {
        id: 'completed',
        value: 'Completado',
      },
      {
        id: 'paid',
        value: 'Pagado',
      },
    ],

    paidList: [
      {
        id: null,
        value: 'Cualquiera',
      },
      {
        id: '1',
        value: 'Sí',
      },
      {
        id: '0',
        value: 'No',
      },
    ],

    activeFrom: false,
    activeTo: false,

    filter: {
      type: [],
      status: [],
      table: null,
      paid: null,
      dateFrom: null,
      dateTo: null,
    },

    tables: [],

    selectedOrders: [],
  }),

  computed: {
    ...mapGetters('history', {
      orders: 'orders',
      last_page: 'last_page',
      historyLoading: 'loading',
    }),

    ...mapGetters('orderDetails', {
      products: 'products',
      dailyMenus: 'dailyMenus',
      orderDetailsLoading: 'loading',
    }),

    typeList() {
      return [
        {
          id: 'order',
          value: this.$t('history.typeList.order'),
        },
        {
          id: 'bill',
          value: this.$t('history.typeList.bill'),
        },
        {
          id: 'waiter',
          value: this.$t('history.typeList.waiter'),
        },
      ];
    },

    tableList() {
      let allTables = [
        {
          uuid: null,
          number: 'Cualquiera',
        },
      ];

      return allTables.concat(this.tables);
    },

    checked() {
      return (
        this.selectedOrders.length == this.orders.length &&
        // Do NOT select the "All orders" check if there are no orders for the filter.
        this.selectedOrders.length != 0
      );
    },

    indeterminate() {
      return (
        this.selectedOrders.length > 0 &&
        this.selectedOrders.length != this.orders.length
      );
    },

    enableUpdatePaidButton() {
      let enable = false;
      this.selectedOrders.forEach(id => {
        let order = this.orders.find(o => o.id == id);
        if (order != null && order.type == 'order' && order.status != 'paid') {
          enable = true;
        }
      });

      return enable;
    },
  },

  created() {
    // Set loading state - We need to set the loading state of the page too so
    // there is no delay on change
    this.loadingTables = true;

    // Set the current page if there is one set
    // (this will trigger the watchet so there is
    // no need to load anything here)
    this.page = this.currentPage();

    // Load all tables
    this.$store
      .dispatch('tables/load', this.$route.params.restaurant)
      .then(r => {
        this.tables = r.data;

        // Asign filter variables
        this.filter.type = this.currentFilterType();
        this.filter.status = this.currentFilterStatus();
        this.filter.table = this.currentFilterTable();
        this.filter.paid = this.currentFilterPaid();
        this.filter.dateFrom = this.currentFilterDateFrom();
        this.filter.dateTo = this.currentFilterDateTo();

        this.load();
      })
      .catch(error => {
        this.storeError(error);
      })
      .finally(() => {
        this.loadingTables = false;
      });
  },

  watch: {
    // $route(to, from) {
    $route() {
      this.deselect();
      this.load();
    },
  },

  methods: {
    load() {
      this.loading = true;

      let payload = {
        restaurant: this.$route.params.restaurant,
        page: this.page,
        filter: this.filter,
      };

      this.$store
        .dispatch('history/filter', payload)
        .then(results => {
          // Clear all errors
          this.clearErrors();

          if (this.page > results.data.last_page) {
            // Reload
            this.search(results.data.last_page);
          }
        })
        .catch(error => {
          this.storeError(error);
        })
        .finally(() => {
          // Clear loading
          this.loading = false;
          // Scroll to top
          goTo(0);
        });
    },

    reset() {
      this.filter.type = [];
      this.filter.status = [];
      this.filter.table = null;
      this.filter.paid = null;
      this.filter.dateFrom = null;
      this.filter.dateTo = null;
    },

    search(page = null) {
      // Filter the orders
      this.$router
        .push({
          name: 'history',
          params: {
            restaurant: this.$route.params.restaurant,
          },
          query: this.query(page),
        })
        .catch(() => {});
    },

    query(page = null) {
      if (page) this.page = page;

      let query = {
        page: this.page,
      };

      // Check all filters
      if (this.filter.type.length > 0) query.type = this.filter.type.join(',');
      if (this.filter.status.length > 0)
        query.status = this.filter.status.join(',');
      if (this.filter.table) query.table = this.filter.table;
      if (this.filter.paid !== null) query.paid = this.filter.paid;
      if (this.filter.dateFrom) query.from = this.filter.dateFrom;
      if (this.filter.dateTo) query.to = this.filter.dateTo;

      return query;
    },

    currentPage() {
      if (isNaN(this.$route.query.page)) {
        return 1;
      } else {
        return parseInt(this.$route.query.page);
      }
    },

    currentFilterType() {
      let type = this.$route.query.type;
      if (!type) {
        return [];
      }

      // Get all order types (type=bill,order,waiter)
      type = type.split(',');
      let filterType = [];

      // Lets check the allowed values
      this.typeList.forEach(value => {
        if (type.indexOf(value.id) > -1) {
          filterType.push(value.id);
        }
      });

      return filterType;
    },

    currentFilterStatus() {
      let status = this.$route.query.status;
      if (!status) {
        return [];
      }

      // Get all statuses (status=new,acepted,completed)
      status = status.split(',');
      let filterStatus = [];

      // Lets check the allowed values
      this.statusList.forEach(value => {
        if (status.indexOf(value.id) > -1) {
          filterStatus.push(value.id);
        }
      });

      return filterStatus;
    },

    currentFilterTable() {
      let table = this.tables.find(t => t.uuid == this.$route.query.table);
      return table ? table.uuid : null;
    },

    currentFilterPaid() {
      let paid = this.$route.query.paid;
      if (paid === undefined) {
        return null;
      }

      return paid.toString();
    },

    currentFilterDateFrom() {
      let dateFrom = dayjs(this.$route.query.from, 'YYYY-MM-DD', true);
      if (dateFrom.isValid()) {
        return dateFrom.format('YYYY-MM-DD');
      }

      return null;
    },

    currentFilterDateTo() {
      let dateTo = dayjs(this.$route.query.to, 'YYYY-MM-DD', true);
      if (dateTo.isValid()) {
        return dateTo.format('YYYY-MM-DD');
      }

      return null;
    },

    deselect() {
      this.selectedOrders = [];
    },

    toggle() {
      if (this.selectedOrders.length == this.orders.length) {
        this.deselect();
      } else {
        this.deselect();
        this.orders.forEach(o => {
          this.selectedOrders.push(o.id);
        });
      }
    },

    toggleOrder(payload) {
      if (payload.checked) {
        // Add to selection
        this.selectedOrders.push(payload.order);
      } else {
        // Remove selection
        this.selectedOrders = this.selectedOrders.filter(
          o => o != payload.order
        );
      }
    },

    open(order) {
      // First we load the order data (only if it is an order)
      if (order.type != 'order') {
        this.order = order;
        this.dialog = true;
        return;
      }

      // Set the loading screen
      this.loading = true;

      // Now we have an order here ... lets load the data first
      const payload = {
        restaurant: this.$route.params.restaurant,
        order: order.id,
      };

      this.$store
        .dispatch('orderDetails/load', payload)
        .then(() => {
          // Clear all errors
          this.clearErrors();

          this.order = order;
          this.dialog = true;
        })
        .catch(error => {
          this.storeError(error);
        })
        .finally(() => (this.loading = false));
    },

    close() {
      this.dialog = false;
      this.ticketDialog = false;
      this.order = null;
    },

    updatePaid(orders) {
      // Update all selected orders (filter only orders)
      if (orders == null) {
        orders = this.selectedOrders.filter(id => {
          let order = this.orders.find(o => o.id == id);
          return order != null && order.type == 'order';
        });
      }
      const payload = {
        restaurant: this.$route.params.restaurant,
        orders: orders,
      };

      this.$store
        .dispatch('orders/updatePaid', payload)
        .then(() => {
          // Close any dialog (we can get here from tiket view )
          this.close();

          // Remove selection
          this.deselect();

          // Reload all data
          this.load();
        })
        .catch(error => {
          // Some error here
          this.storeError(error);
        });
    },

    save(payload) {
      // Start loader
      this.loading = true;
      this.close();
      this.$store
        .dispatch('orders/updateStatus', payload)
        // Reload data - the load function will set to false the loading flag
        .then(() => this.load())
        .catch(error => {
          this.storeError(error);
          this.loading = false;
        });
    },

    showTicketDialog() {
      this.dialog = false;
      this.ticketDialog = true;
    },

    showDialog() {
      this.dialog = true;
      this.ticketDialog = false;
    },
  },
};
</script>

<style>
.order-history > .layout > .flex > .v-card__text {
  padding: 2px 8px;
}
.order-history.v-card__text {
  padding: 0px 8px;
}

.order-history.v-card.v-card--flat.v-sheet.theme--light {
  border-bottom: 1px solid #ccc;
}
</style>
