<template>
  <v-row justify="center">
    <v-dialog
      :value="dialog"
      @input="$emit('close')"
      max-width="800px"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card v-if="order">
        <v-card-title class="pb-0">
          <span v-if="type == 'order'" class="headline">
            <v-icon class="mr-2" size="28px">mdi-shopping</v-icon>Nuevo pedido
          </span>
          <span v-else-if="type == 'waiter'" class="headline">
            <v-icon class="mr-2" size="28px">icon-waiter</v-icon>Te necesitan
          </span>
          <span v-else class="headline">
            <v-icon class="mr-2" size="28px">icon-bill</v-icon>La cuenta
          </span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <!-- Start order details -->
            <v-row>
              <v-col cols="12">
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left" colspan="2">Detalle evento</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Fecha y hora (primer pedido)</td>
                        <td class="font-weight-medium text-right">
                          {{ dateTime }}
                        </td>
                      </tr>
                      <tr>
                        <td>Fecha y hora (última actualización)</td>
                        <td class="font-weight-medium text-right">
                          {{ dateTimeUpdated }}
                        </td>
                      </tr>
                      <tr>
                        <td>Número de mesa</td>
                        <td class="font-weight-medium text-right">
                          {{ order.table.number }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span
                            v-if="order.placed_by_type == 'App\\Models\\User'"
                          >
                            Camarero
                          </span>
                          <span v-else>Cliente</span>
                        </td>
                        <td class="font-weight-medium text-right">
                          <span
                            v-if="order.placed_by_type == 'App\\Models\\User'"
                          >
                            {{
                              order.placed_by.first_name +
                              ' ' +
                              order.placed_by.first_surname
                            }}
                          </span>
                          <span v-else>{{ order.placed_by.key }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <!-- <v-list subheader>
                  <v-subheader>Detalle evento</v-subheader>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Fecha y hora</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action class="font-weight-medium">{{
                      dateTime
                    }}</v-list-item-action>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Número de mesa</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action class="font-weight-medium">{{
                      order.table.number
                    }}</v-list-item-action>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span v-if="order.placed_by_type == 'App\\Models\\User'"
                          >Camarero</span
                        >
                        <span v-else>Cliente</span>
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action class="font-weight-medium">
                      <span
                        v-if="order.placed_by_type == 'App\\Models\\User'"
                        >{{
                          order.placed_by.first_name +
                          ' ' +
                          order.placed_by.first_surname
                        }}</span
                      >
                      <span v-else>{{ order.placed_by.key }}</span>
                    </v-list-item-action>
                  </v-list-item>
                </v-list> -->
              </v-col>
            </v-row>
            <!-- End order details -->

            <v-row wrap v-if="type == 'order'">
              <!-- Start products list -->
              <v-col
                cols="12"
                v-if="servedProducts.length > 0 && products.length > 0"
              >
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left pr-0" width="32px"></th>
                        <th class="text-left pl-0">Producto</th>
                        <th class="text-right d-none d-sm-table-cell">
                          Precio
                        </th>
                        <th class="text-right">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(product, index) in products"
                        :key="product.id"
                        @click="toggleServedProducts(product.id)"
                        class="order-product"
                      >
                        <td class="text-left pr-0" width="32px">
                          <v-simple-checkbox
                            color="primary"
                            v-model="servedProducts[index].served"
                          ></v-simple-checkbox>
                        </td>
                        <td
                          class="text-left pl-0"
                          :class="
                            servedProducts[index].served
                              ? 'text-decoration-line-through'
                              : ''
                          "
                        >
                          {{ product.name | translate($i18n) }}
                        </td>
                        <td
                          class="text-right d-none d-sm-table-cell"
                          :class="
                            servedProducts[index].served
                              ? 'text-decoration-line-through'
                              : ''
                          "
                        >
                          {{ product.quantity }}
                          x
                          {{ product.price | currency_money }}
                        </td>
                        <td
                          class="text-right"
                          :class="
                            servedProducts[index].served
                              ? 'text-decoration-line-through'
                              : ''
                          "
                        >
                          {{
                            (product.price * product.quantity) | currency_money
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
              <!-- End products list -->

              <!-- Daily menus -->
              <v-col cols="12" v-if="dailyMenus.length > 0">
                <v-simple-table
                  v-for="(dailyMenu, index) in dailyMenus"
                  :key="'daily_menu_' + dailyMenu.id"
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left pr-0" width="32px"></th>
                        <th class="text-left pl-0" colspan="3">
                          <div class="d-flex justify-space-between">
                            <span>{{ dailyMenu.name | translate($i18n) }}</span>
                            <span>{{ dailyMenu.price | currency_money }}</span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- BEVERAGES -->
                      <tr
                        class="order-product"
                        @click="toggleBeverage(dailyMenu.id)"
                      >
                        <td class="text-left pr-0" width="32px">
                          <v-simple-checkbox
                            color="primary"
                            v-model="dailyMenusServed[index].beverage.served"
                          ></v-simple-checkbox>
                        </td>
                        <td class="text-left pl-0">
                          <v-icon class="mr-2">mdi-beer</v-icon>
                          <span
                            class="d-none d-sm-inline"
                            :class="
                              dailyMenusServed[index].beverage.served
                                ? 'text-decoration-line-through'
                                : ''
                            "
                          >
                            Bebida
                          </span>
                        </td>
                        <td
                          :class="
                            dailyMenusServed[index].beverage.served
                              ? 'text-decoration-line-through'
                              : ''
                          "
                        >
                          {{
                            dailyMenu.object_data.beverage.name
                              | translate($i18n)
                          }}
                        </td>
                        <td
                          class="text-right"
                          :class="
                            dailyMenusServed[index].beverage.served
                              ? 'text-decoration-line-through'
                              : ''
                          "
                        >
                          {{ dailyMenu.object_data.beverage.price | money }}
                        </td>
                      </tr>

                      <!-- FIRST DISHES -->
                      <tr
                        v-for="(first_dish, ifd) in dailyMenu.object_data
                          .first_dishes"
                        :key="'fd_' + first_dish.id"
                        class="order-product"
                        @click="toggleFirstDishes(dailyMenu.id, ifd)"
                      >
                        <td class="text-left pr-0" width="10px">
                          <v-simple-checkbox
                            v-model="
                              dailyMenusServed[index].first_dishes[ifd].served
                            "
                            color="primary"
                          ></v-simple-checkbox>
                        </td>
                        <td class="text-left pl-0">
                          <v-icon class="mr-2">mdi-silverware</v-icon>
                          <span
                            class="d-none d-sm-inline"
                            :class="
                              dailyMenusServed[index].first_dishes[ifd].served
                                ? 'text-decoration-line-through'
                                : ''
                            "
                          >
                            Primer plato
                          </span>
                        </td>
                        <td
                          :class="
                            dailyMenusServed[index].first_dishes[ifd].served
                              ? 'text-decoration-line-through'
                              : ''
                          "
                        >
                          {{ first_dish.name | translate($i18n) }}
                        </td>
                        <td
                          class="text-right"
                          :class="
                            dailyMenusServed[index].first_dishes[ifd].served
                              ? 'text-decoration-line-through'
                              : ''
                          "
                        >
                          {{ first_dish.price | money }}
                        </td>
                      </tr>

                      <!-- SECOND DISHES -->
                      <tr
                        v-for="(second_dish, isd) in dailyMenu.object_data
                          .second_dishes"
                        :key="'sd_' + second_dish.id"
                        class="order-product"
                        @click="toggleSecondDishes(dailyMenu.id, isd)"
                      >
                        <td class="text-left pr-0" width="10px">
                          <v-simple-checkbox
                            color="primary"
                            v-model="
                              dailyMenusServed[index].second_dishes[isd].served
                            "
                          ></v-simple-checkbox>
                        </td>
                        <td class="text-left pl-0">
                          <v-icon class="mr-2">mdi-silverware</v-icon>
                          <span
                            class="d-none d-sm-inline"
                            :class="
                              dailyMenusServed[index].second_dishes[isd].served
                                ? 'text-decoration-line-through'
                                : ''
                            "
                            >Segundo plato</span
                          >
                        </td>
                        <td
                          :class="
                            dailyMenusServed[index].second_dishes[isd].served
                              ? 'text-decoration-line-through'
                              : ''
                          "
                        >
                          {{ second_dish.name | translate($i18n) }}
                        </td>

                        <td
                          class="text-right"
                          :class="
                            dailyMenusServed[index].second_dishes[isd].served
                              ? 'text-decoration-line-through'
                              : ''
                          "
                        >
                          {{ second_dish.price | money }}
                        </td>
                      </tr>

                      <!-- DESSERTS -->
                      <tr
                        v-if="dailyMenu.object_data.dessert"
                        class="order-product"
                        @click="toggleDessert(dailyMenu.id)"
                      >
                        <td class="text-left pr-0" width="10px">
                          <v-simple-checkbox
                            color="primary"
                            v-model="dailyMenusServed[index].dessert.served"
                          ></v-simple-checkbox>
                        </td>
                        <td class="text-left pl-0">
                          <v-icon class="mr-2">mdi-cake</v-icon>
                          <span
                            class="d-none d-sm-inline"
                            :class="
                              dailyMenusServed[index].dessert.served
                                ? 'text-decoration-line-through'
                                : ''
                            "
                          >
                            Postre
                          </span>
                        </td>
                        <td
                          :class="
                            dailyMenusServed[index].dessert.served
                              ? 'text-decoration-line-through'
                              : ''
                          "
                        >
                          {{
                            dailyMenu.object_data.dessert.name
                              | translate($i18n)
                          }}
                        </td>

                        <td
                          class="text-right"
                          :class="
                            dailyMenusServed[index].dessert.served
                              ? 'text-decoration-line-through'
                              : ''
                          "
                        >
                          {{ dailyMenu.object_data.dessert.price | money }}
                        </td>
                      </tr>

                      <!-- COFFEE -->
                      <tr
                        v-if="dailyMenu.object_data.coffee"
                        class="order-product"
                        @click="toggleCoffee(dailyMenu.id)"
                      >
                        <td class="text-left pr-0" width="10px">
                          <v-simple-checkbox
                            color="primary"
                            v-model="dailyMenusServed[index].coffee.served"
                          ></v-simple-checkbox>
                        </td>
                        <td class="pl-0">
                          <v-icon class="mr-2">mdi-cake</v-icon>
                          <span
                            class="d-none d-sm-inline"
                            :class="
                              dailyMenusServed[index].coffee.served
                                ? 'text-decoration-line-through'
                                : ''
                            "
                          >
                            Café
                          </span>
                        </td>
                        <td
                          :class="
                            dailyMenusServed[index].coffee.served
                              ? 'text-decoration-line-through'
                              : ''
                          "
                        >
                          {{
                            dailyMenu.object_data.coffee.name | translate($i18n)
                          }}
                        </td>
                        <td
                          class="text-right"
                          :class="
                            dailyMenusServed[index].coffee.served
                              ? 'text-decoration-line-through'
                              : ''
                          "
                        >
                          {{ dailyMenu.object_data.coffee.price | money }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
              <!-- Daily menus -->

              <!-- Total Price -->
              <v-col cols="12" class="py-0" v-if="order.price">
                <v-list>
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-medium">
                        Precio total
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action class="font-weight-medium">{{
                      order.price | currency_money
                    }}</v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
              <!-- Total Price -->
            </v-row>

            <v-row>
              <v-col cols="12" class="py-0" v-if="details != null">
                <v-list two-line subheader>
                  <v-subheader>Detalles del cliente</v-subheader>
                </v-list>
              </v-col>

              <v-col cols="12" class="py-0" v-if="details != null">
                <blockquote class="blockquote pt-0 text-xs-justify mb-3">
                  {{ details }}
                </blockquote>
              </v-col>

              <v-col cols="12" class="py-0">
                <v-select
                  :disabled="order.status == 'paid'"
                  v-model="status"
                  :items="statuses"
                  label="Estado del pedido."
                  item-value="id"
                  item-text="name"
                  prepend-icon="mdi-tag"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="d-block d-sm-flex">
          <v-btn
            :block="$vuetify.breakpoint.xsOnly"
            @click="openHistory"
            v-if="hideHistoryButton == false"
            class="my-4"
          >
            Pedidos de la mesa {{ order.table.number }}
          </v-btn>

          <v-btn
            :block="$vuetify.breakpoint.xsOnly"
            :class="$vuetify.breakpoint.xsOnly ? 'ml-0' : ''"
            @click="$emit('showTicketDialog')"
            v-if="hideTicketViewButton == false && order.type == 'order'"
          >
            Vista Ticket
          </v-btn>

          <div class="flex-grow-1"></div>

          <v-btn
            :block="$vuetify.breakpoint.xsOnly"
            class="my-4"
            @click="close"
          >
            Cancelar
          </v-btn>

          <v-btn
            :block="$vuetify.breakpoint.xsOnly"
            :disabled="order.status == 'paid'"
            color="primary"
            :class="$vuetify.breakpoint.xsOnly ? 'ml-0' : ''"
            @click="save"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import dayjs from 'dayjs';

export default {
  props: {
    order: {},
    products: {},
    dailyMenus: {},
    hideHistoryButton: Boolean,
    hideTicketViewButton: Boolean,
  },

  data: () => ({
    dialog: false,

    servedProducts: [],
    dailyMenusServed: [],

    type: '',
    details: null,
    paid: false,

    // FIXME: Create a new database table for this with languages
    status: 'new',
    statuses: [
      {
        id: 'new',
        name: 'Nuevo',
      },
      {
        id: 'rejected',
        name: 'Rechazado',
      },
      {
        id: 'acepted',
        name: 'Aceptado / preparando',
      },
      {
        id: 'completed',
        name: 'Completado',
      },
      {
        id: 'paid',
        name: 'Pagado',
      },
    ],
    blockClient: false,
  }),

  watch: {
    order(newValue) {
      if (newValue == null) {
        this.servedProducts = [];
        this.dailyMenusServed = [];
        this.dialog = false;
      } else {
        // Every time we open the dialog we need to copy the properties

        this.type = this.order.type;
        this.details = this.order.details;
        this.paid = this.order.paid;
        this.status = this.order.status;

        this.products.forEach(product =>
          this.servedProducts.push({
            id: product.id,
            served: !!product.object_data?.served,
          })
        );

        this.createServedDailyMenus();

        this.dialog = true;
      }
    },
  },

  computed: {
    dateTime() {
      return this.order != null
        ? dayjs(this.order.created_at).format('D MMM YYYY [a las] HH:mm')
        : '';
    },

    dateTimeUpdated() {
      return this.order != null
        ? dayjs(this.order.updated_at).format('D MMM YYYY [a las] HH:mm')
        : '';
    },
  },

  methods: {
    close() {
      this.servedProducts = [];
      this.$emit('close');
    },

    save() {
      // Save and if all worked then close the window
      let payload = {
        status: this.status,
        paid: this.paid,
        order: this.order,
        servedProducts: this.servedProducts,
        servedDailyMenus: this.dailyMenusServed,
      };

      this.$emit('save', payload);
    },

    openHistory() {
      // First close this dialog
      this.$emit('close');

      // Prepare the query
      const query = {
        page: 1,
        type: 'order',
        status: 'new,acepted,completed',
        table: this.order.table.uuid,
        paid: this.paid,
        from: dayjs().format('YYYY-MM-DD'),
        to: dayjs().format('YYYY-MM-DD'),
      };

      // Now lets go to the history page with today filter and
      this.$router.push({
        name: 'history',
        params: {
          restaurant: this.$route.params.restaurant,
        },
        query,
      });
    },

    createServedDailyMenus() {
      this.dailyMenus.forEach(dailyMenu => {
        const servedDailyMenu = {
          id: dailyMenu.id,
          beverage: {
            id: dailyMenu.object_data.beverage.id,
            served: !!dailyMenu.object_data.beverage.served,
          },
          first_dishes: this.createServedDishes(
            dailyMenu.object_data.first_dishes
          ),
          second_dishes: this.createServedDishes(
            dailyMenu.object_data.second_dishes
          ),
          dessert: {
            id: dailyMenu.object_data.dessert?.id,
            served: !!dailyMenu.object_data.dessert?.served,
          },
          coffee: {
            id: dailyMenu.object_data.coffee?.id,
            served: !!dailyMenu.object_data.coffee?.served,
          },
        };

        this.dailyMenusServed.push(servedDailyMenu);
      });
    },

    createServedDishes(dishes) {
      if (!dishes) return [];
      const results = [];
      dishes.forEach(dish =>
        results.push({
          id: dish.id,
          served: !!dish.served,
        })
      );
      return results;
    },

    toggleServedProducts(id) {
      const product = this.servedProducts.find(product => product.id === id);
      if (product !== undefined) {
        product.served = !product.served;
      }
    },

    /** Toggle Daily Menu served flags */
    toggleBeverage(id) {
      const dailyMenu = this.dailyMenusServed.find(
        dailyMenu => dailyMenu.id === id
      );
      if (dailyMenu !== undefined) {
        dailyMenu.beverage.served = !dailyMenu.beverage.served;
      }
    },

    toggleFirstDishes(id, ifd) {
      const dailyMenu = this.dailyMenusServed.find(
        dailyMenu => dailyMenu.id === id
      );
      if (dailyMenu !== undefined) {
        dailyMenu.first_dishes[ifd].served = !dailyMenu.first_dishes[ifd]
          .served;
      }
    },

    toggleSecondDishes(id, isd) {
      const dailyMenu = this.dailyMenusServed.find(
        dailyMenu => dailyMenu.id === id
      );
      if (dailyMenu !== undefined) {
        dailyMenu.second_dishes[isd].served = !dailyMenu.second_dishes[isd]
          .served;
      }
    },

    toggleDessert(id) {
      const dailyMenu = this.dailyMenusServed.find(
        dailyMenu => dailyMenu.id === id
      );
      if (dailyMenu !== undefined) {
        dailyMenu.dessert.served = !dailyMenu.dessert.served;
      }
    },

    toggleCoffee(id) {
      const dailyMenu = this.dailyMenusServed.find(
        dailyMenu => dailyMenu.id === id
      );
      if (dailyMenu !== undefined) {
        dailyMenu.coffee.served = !dailyMenu.coffee.served;
      }
    },
  },
};
</script>

<style>
.order-product {
  cursor: pointer;
}
</style>
