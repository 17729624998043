<template>
  <v-row justify="center">
    <v-dialog :value="dialog" @input="$emit('close')" fullscreen>
      <v-card v-if="order">
        <v-card-text class="pa-0 pt-5">
          <v-card
            max-width="400px"
            class="mx-auto"
            :flat="$vuetify.breakpoint.xsOnly"
          >
            <v-card-title class="pb-0">
              <span class="headline">
                <v-icon class="mr-2" size="28px">icon-bill</v-icon> La cuenta
              </span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <!-- Start order details -->
                <v-row>
                  <v-col cols="12">
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left" colspan="2">Detalle</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Fecha y hora</td>
                            <td class="font-weight-medium text-right">
                              {{ dateTime }}
                            </td>
                          </tr>
                          <tr>
                            <td>Número de mesa</td>
                            <td class="font-weight-medium text-right">
                              {{ order.table.number }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
                <!-- End order details -->

                <v-row wrap v-if="order.type == 'order'">
                  <!-- Start products list -->
                  <v-col
                    cols="12"
                    v-if="
                      joinedProducts.length > 0 || joinedDailyMenus.length > 0
                    "
                  >
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left pl-0">Producto</th>
                            <th class="text-right">Precio</th>
                            <th class="text-right">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="product in joinedProducts"
                            :key="product.id"
                            class="order-product"
                          >
                            <td class="text-left pl-0">
                              {{ product.name | translate($i18n) }}
                            </td>
                            <td class="text-right">
                              {{ product.quantity }}
                              x
                              {{ product.price | money }}
                            </td>
                            <td class="text-right pr-0">
                              {{ (product.price * product.quantity) | money }}
                            </td>
                          </tr>

                          <tr
                            v-for="dailyMenu in joinedDailyMenus"
                            :key="dailyMenu.id"
                            class="order-product"
                          >
                            <td class="text-left pl-0">
                              {{ dailyMenu.name | translate($i18n) }}
                            </td>
                            <td class="text-right">
                              {{ dailyMenu.quantity }}
                              x
                              {{ dailyMenu.price | money }}
                            </td>
                            <td class="text-right pr-0">
                              {{
                                (dailyMenu.price * dailyMenu.quantity) | money
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                  <!-- End products list -->

                  <!-- Total Price -->
                  <v-col cols="12" class="py-0 px-0" v-if="order.price">
                    <v-list class="">
                      <v-divider></v-divider>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="font-weight-medium">
                            TOTAL
                          </v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action class="font-weight-medium">
                          {{ order.price | money }}
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <!-- Total Price -->
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions class="d-block">
              <v-btn block @click="$emit('showDialog')"> Vista pedido </v-btn>

              <div class="flex-grow-1"></div>

              <v-btn
                block
                @click="$emit('updatePaid', [order.id])"
                class="my-4"
                color="warning"
              >
                Marcar Pagado
              </v-btn>

              <div class="flex-grow-1"></div>

              <v-btn block class="my-2" @click="close"> Cancelar </v-btn>
            </v-card-actions>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import dayjs from 'dayjs';

export default {
  props: {
    dialog: Boolean,
    order: {},
    products: {},
    dailyMenus: {},
    hideHistoryButton: Boolean,
  },

  data: () => ({}),

  computed: {
    joinedProducts() {
      // We need to join all products with same type and ID and sum their quantities
      const joined = [];
      this.products.forEach(product => {
        let foundIndex = -1;
        const foundProduct = joined.find((p, index) => {
          if (
            product.buyable_id == p.buyable_id &&
            product.buyable_type == p.buyable_type
          ) {
            foundIndex = index;
            return p;
          }
          return undefined;
        });

        if (foundProduct === undefined) {
          joined.push({
            buyable_id: product.buyable_id,
            buyable_type: product.buyable_type,
            quantity: product.quantity,
            price: product.price,
            name: product.name,
          });
        } else {
          foundProduct.quantity += product.quantity;
          joined.splice(foundIndex, 1, foundProduct);
        }
      });

      return joined;
    },

    joinedDailyMenus() {
      const joined = [];
      this.dailyMenus.forEach(dailyMenu => {
        let foundIndex = -1;
        const foundDailyMenu = joined.find((d, index) => {
          if (
            dailyMenu.buyable_id == d.buyable_id &&
            dailyMenu.buyable_type == d.buyable_type &&
            dailyMenu.price == d.price
          ) {
            foundIndex = index;
            return d;
          }
          return undefined;
        });

        if (foundDailyMenu === undefined) {
          joined.push({ ...dailyMenu });
        } else {
          foundDailyMenu.quantity++;
          joined.splice(foundIndex, 1, foundDailyMenu);
        }
      });

      return joined;
    },

    dateTime() {
      return dayjs().format('D MMM YYYY [a las] HH:mm');
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style></style>
