<template>
  <v-row no-gutters class="px-1">
    <v-col class="shrink">
      <v-card-text class="order-history">
        <v-checkbox
          :value="order.id"
          :input-value="checked"
          @change="toggle"
          color="primary"
        ></v-checkbox>
      </v-card-text>
    </v-col>

    <v-col class="grow" @click="$emit('open', order)">
      <v-card flat tile class="order-history fill-height" ripple>
        <v-row align="center" class="fill-height" no-gutters>
          <v-col cols="2" md="1">
            <v-card-text class="subtitle-1 text-truncate px-1">
              <v-icon class="mr-2">mdi-table-chair</v-icon>
              <span>{{ order.table.number }}</span>
            </v-card-text>
          </v-col>

          <v-col class="grow d-none d-md-flex">
            <v-card-text class="body-2 font-weight-medium text--primary px-1">
              <v-icon class="black--text mr-2">mdi-history</v-icon>
              {{ dateTime }}
            </v-card-text>
          </v-col>

          <v-col cols="2" md="1">
            <v-card-text class="subtitle-1 px-1">{{
              order.type == 'order'
                ? order.status == 'paid'
                  ? 'Sí'
                  : 'No'
                : 'n/a'
            }}</v-card-text>
          </v-col>

          <v-col cols="4" md="3">
            <v-card-text class="subtitle-1 text-truncate text--primary px-1">
              <v-icon class="black--text mr-2">{{ icon }}</v-icon>
              <span>{{ status }}</span>
            </v-card-text>
          </v-col>

          <v-col cols="4" md="3">
            <v-card-text class="text-right body-2 px-1">
              <span v-if="order.price">
                <v-icon class="black--text mr-2">mdi-currency-eur</v-icon>
                {{ order.price | currency_money }}
              </span>
              <span v-if="order.type == 'bill' && order.object_data">
                <span v-if="order.object_data.payment_type == 'card'">
                  <v-icon class="black--text mr-2">
                    mdi-credit-card-outline
                  </v-icon>
                  Pago con tarjeta
                </span>

                <span v-else>
                  <v-icon class="black--text mr-2">mdi-cash-multiple</v-icon>
                  Pago en efectivo
                </span>
              </span>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import dayjs from 'dayjs';
export default {
  props: {
    order: Object,
    selectedOrders: Array,
  },

  computed: {
    icon() {
      if (this.order.type == 'waiter') return 'icon-waiter';
      if (this.order.type == 'order') return 'mdi-shopping';
      return 'icon-bill';
    },

    status() {
      if (this.order.status == 'new') return 'Nuevo';
      if (this.order.status == 'acepted') return 'Aceptado';
      if (this.order.status == 'rejected') return 'Rechazado';
      if (this.order.status == 'paid') return 'Pagado';
      return 'Completado';
    },

    details() {
      return this.order.details;
    },

    checked() {
      return this.selectedOrders.find(order => order == this.order.id);
    },

    dateTime() {
      return dayjs(this.order.created_at).format('DD/MM/YYYY HH:mm:ss');
    },
  },

  methods: {
    toggle(event) {
      console.log('toggle');
      const payload = {
        order: this.order.id,
        checked: event == this.order.id,
      };

      this.$emit('toggle', payload);
    },

    openDetails() {
      this.$emit('open', this.order);
    },
  },
};
</script>

<style></style>
